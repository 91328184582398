/* Updated navbar.css */

/* Mobile Styles */
@media (max-width: 1024px) {
  .mobile-menu-icon {
      display: block;
      color: white;
      background: none;
      border: none;
      cursor: pointer;
      z-index: 20; /* Ensure it's on top of other elements */
  }

  .mobile-menu-icon svg {
      width: 24px;
      height: 24px;
      fill: white;
  }

  .navbar-menu {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s ease-in-out;
      background-color: #333; /* Background color for the dropdown */
  }

  .navbar-menu.mobile-active {
      max-height: 500px;
  }
}

/* Desktop Styles */
@media (min-width: 1025px) {
  .mobile-menu-icon {
      display: none;
  }
}

