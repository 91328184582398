@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --transition-speed: 0.3s;
    --primary-color: #3490dc; /* Example primary color */
}

/* Services Section */
#services {
    background: linear-gradient(to top, #ebf8ff, #f0f9ff);
    padding-top: 56px;
    padding-bottom: 5rem;
}

/* Base styles for the card */
.card {
    position: relative;
    overflow: hidden;
    border-radius: 15px; /* Rounded corners for cards */
    cursor: pointer; /* Indicates that the card is interactive */
    background-color: #fff; /* White background for card content */
    padding: 0; /* Ensure padding is consistent with the card body */
    transition: box-shadow var(--transition-speed) ease, transform var(--transition-speed) ease;
    height: 300px; /* Adjust height as needed */
    transform-style: preserve-3d;
    perspective: 1000px; /* Adding perspective for 3D effect */
}

/* Hover effect for card */
.card:hover {
    transform: rotateY(15deg) rotateX(10deg) scale(1.05);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 0.2); /* Reduce background visibility by 80% */
}

/* Background image styling */
.card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    opacity: 1; /* Fully visible by default */
    transition: opacity var(--transition-speed) ease;
    z-index: 0; /* Positioned behind the card content */
}

/* Hover effect for background image */
.card:hover::before {
    opacity: 0.2; /* Reduce visibility by 80% */
}

/* Image styling */
.card-img-top {
    width: 100%;
    height: 200px;
    object-fit: cover;
    transition: transform var(--transition-speed) ease;
}

/* Hover effect for image */
.card:hover .card-img-top {
    transform: scale(1.1);
}

/* Card body styling */
.card-body {
    position: relative;
    z-index: 1; /* Ensure the text is above the background image */
    padding: 15px; /* Padding inside the card */
    color: #333; /* Text color */
}

/* Card title styling */
.card-title {
    font-size: 1.25rem; /* Adjust the size as needed */
    margin-bottom: 0.75rem; /* Space below the title */
}

/* Card detail text styling */
.card-detail-text {
    font-size: 0.875rem; /* Adjust the size as needed */
}

/* Card details styling */
.card-details {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 15px;
    opacity: 0;
    visibility: hidden;
    transition: opacity var(--transition-speed) ease, visibility var(--transition-speed) ease;
    z-index: 0;
}

/* Show details on hover */
.card:hover .card-details {
    opacity: 1;
    visibility: visible;
}

/* Specific card styles */
.card-residential-moving::before {
    background-image: url('https://plus.unsplash.com/premium_photo-1683141107777-8309fc981326?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8fHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
}

.card-commercial-moving::before {
    background-image: url('https://images.unsplash.com/photo-1600725935160-f67ee4f6084a?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8fHxzZWFyY2h8NXx8Y29tbWVyaWNhbCUyMG1vdmluZ3xlbnwwfHwwfHx8MA%3D%3D');
}

.card-storage-solutions::before {
    background-image: url('https://images.unsplash.com/photo-1469289759076-d1484757abc3?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8fHxzZWFyY2h8M3x8U2VjdXJlJTIwc2hvcnQlMjB0ZXJtJTIwYW5kJTIwbG9uZyUyMHRlcm0lMjBzdG9yYWdlJTIwb3B0aW9uc3xlbnwwfHwwfHx8MA%3D%3D');
}

.card-packing-services::before {
    background-image: url('https://plus.unsplash.com/premium_photo-1676057875109-6899ca4ae838?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8fHxzZWFyY2h8OXx8cGFja2luZ3xlbnwwfHwwfHx8MA%3D%3D');
}

.card-unpacking-services::before {
    background-image: url('https://plus.unsplash.com/premium_photo-1670002259521-345ab6bb758a?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8fHxzZWFyY2h8MXx8dW5wYWNraW5nfGVufDB8fDB8fHww');
}

.card-specialized-moving::before {
    background-image: url('https://images.unsplash.com/photo-1615095432023-77f17ef6fab8?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8fHxzZWFyY2h8NHx8ZGVsaWNhdGUlMjBtb3Zpbmd8ZW58MHx8MHx8fDA%3D');
}

.card-vehicle-transporting::before {
    background-image: url('https://images.unsplash.com/photo-1534081278205-74cdb8585879?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8fHxzZWFyY2h8MTZ8fGNhciUyMHRyYW5zcG9ydHxlbnwwfHwwfHx8MA%3D%3D');
}
