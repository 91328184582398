/* Footer Base Styling */
.footer {
  background-color: #333; /* Dark background for contrast */
  color: #e0e0e0; /* Light gray text */
  font-family: 'Roboto', sans-serif; /* Modern font */
  padding: 2rem 1rem; /* Add padding for better spacing */
}

/* Navigation Links Styling */
.nav-links {
  display: flex;
  flex-wrap: wrap; /* Wrap links to new line on small screens */
  gap: 1rem; /* Spacing between links */
  justify-content: center; /* Center links on small screens */
}

.nav-links .nav-link,
.nav-links .btn {
  color: #e0e0e0;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  transition: color 0.3s ease, transform 0.3s ease;
}

.nav-links .nav-link:hover,
.nav-links .btn:hover {
  color: #f39c12; /* Gold color on hover */
  transform: translateY(-2px); /* Subtle hover effect */
}

/* Follow Us Section */
.social-icons {
  display: flex;
  justify-content: center; /* Center icons on small screens */
  gap: 0.75rem; /* Spacing between icons */
}

.social-icons .social-icon {
  font-size: 1.25rem;
  color: #e0e0e0;
  transition: color 0.3s ease, transform 0.3s ease;
}

.social-icons .social-icon:hover {
  color: #f39c12; /* Gold color on hover */
  transform: scale(1.1); /* Slightly larger on hover */
}

/* Subscribe Form Section */
.subscribe {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.75rem; /* Add gap between form elements */
  max-width: 400px; /* Limit form width for better readability */
  margin: 0 auto; /* Center the form */
}

.subscribe .form-control {
  border: 1px solid #444; /* Darker border */
  border-radius: 4px;
  padding: 0.5rem;
  font-size: 0.875rem;
  transition: border-color 0.3s ease;
  width: 100%; /* Make form fields take full width */
}

.subscribe .form-control:focus {
  border-color: #f39c12; /* Gold color on focus */
  outline: none;
}

.subscribe .btn-primary {
  background-color: #007bff; /* Primary blue color */
  border: none;
  font-weight: 600;
  padding: 0.5rem 1rem; /* Reduced padding */
  border-radius: 4px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.subscribe .btn-primary:hover {
  background-color: #0056b3; /* Darker blue on hover */
  transform: translateY(-1px); /* Subtle lift on hover */
}

/* Footer Text */
.footer-text {
  color: #e0e0e0;
  margin-top: 1.5rem;
  text-align: center; /* Center the footer text */
}

/* Responsive Design */
@media (min-width: 768px) {
  .nav-links {
      justify-content: flex-start; /* Align links to the left on medium screens */
  }

  .subscribe {
      flex-direction: row; /* Arrange form fields in a row on medium screens */
      align-items: center;
  }

  .subscribe .form-control {
      width: auto; /* Allow form fields to shrink to fit content */
      flex: 1; /* Let form fields take up remaining space */
  }
}

@media (min-width: 1024px) {
  .footer .container {
      padding: 2rem 2rem; /* Slightly increased padding */
  }

  .footer h5 {
      font-size: 1.25rem;
  }

  .social-icons .social-icon {
      font-size: 1.5rem; /* Larger icons on larger screens */
  }

  .subscribe .form-control {
      font-size: 1rem;
      padding: 0.75rem;
  }

  .subscribe .btn-primary {
      font-size: 1rem;
      padding: 0.75rem 1.5rem;
  }
}
