:root {
  --gradient-start: #ebf8ff;
  --gradient-end: #f0f9ff;
  --padding-top: 56px;
  --padding-bottom: 5rem;
}

#why {
  background: linear-gradient(to bottom, var(--gradient-start), var(--gradient-end));
  padding-top: var(--padding-top);
  padding-bottom: var(--padding-bottom);
}
