/* Locomotive Scroll CSS */
@import url('https://cdn.jsdelivr.net/npm/locomotive-scroll@4.1.0/dist/locomotive-scroll.min.css');

/* Additional Styles */
.main-container {
  overflow: hidden; /* Ensure the container hides overflow */
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* CSS Reset to ensure consistency across browsers */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  font-family: 'Arial', sans-serif; /* Set your default font */
  font-size: 16px; /* Set your default font size */
  line-height: 1.5;
  color: #333; /* Set your default text color */
  background-color: #f8f8f8; /* Set your default background color */
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

a {
  text-decoration: none;
  color: inherit; /* Links inherit color from their context */
}

ul, ol {
  list-style: none;
}

button {
  cursor: pointer;
  border: none;
  background-color: transparent;
}

input, textarea, select, button {
  font-family: inherit;
  font-size: inherit;
}

/* Typography */
h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
  margin-bottom: 0.5em;
}

p {
  margin-bottom: 1em;
}

/* Utility Classes */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-center {
  text-align: center;
}

.mt-1 { margin-top: 1rem; }
.mt-2 { margin-top: 2rem; }
.mt-3 { margin-top: 3rem; }

.mb-1 { margin-bottom: 1rem; }
.mb-2 { margin-bottom: 2rem; }
.mb-3 { margin-bottom: 3rem; }

.pt-1 { padding-top: 1rem; }
.pt-2 { padding-top: 2rem; }
.pt-3 { padding-top: 3rem; }

.pb-1 { padding-bottom: 1rem; }
.pb-2 { padding-bottom: 2rem; }
.pb-3 { padding-bottom: 3rem; }

/* Layout */
header, footer {
  background-color: #333;
  color: white;
  padding: 1rem 0;
}

main {
  padding: 2rem 0;
}

/* Buttons */
button.primary {
  background-color: #007bff;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

button.primary:hover {
  background-color: #0056b3;
}

/* Forms */
input, textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 1rem;
}

/* Media Queries */
@media (max-width: 768px) {
  .container {
    padding: 0 10px;
  }

  .flex {
    flex-direction: column;
  }
}
