/* Network Section Styling */
#network {
  background-color: #f8f9fa; /* Light background color */
  perspective: 1000px; /* Add perspective to parent element */
  background: linear-gradient(to bottom, #ebf8ff, #f0f9ff); /* Gradient background */
  padding-top: 56px;
  padding-bottom: 5rem;
}

#network .col-md-3 {
  margin-bottom: 1.5rem; /* Space between cards */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effects */
}

#network .col-md-3:hover {
  transform: rotateY(10deg) rotateX(10deg); /* 3D rotation effect on hover */
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2); /* Shadow for lifted effect */
}

#network .text-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#network i {
  color: #007bff; /* Primary color for icons */
  margin-bottom: 0.5rem; /* Spacing below icons */
  transition: transform 0.3s ease; /* Smooth transition for icon hover */
}

#network i:hover {
  transform: scale(1.2); /* Slight zoom effect on hover */
}

#network h5 {
  margin-bottom: 0.5rem; /* Spacing below headings */
  font-weight: bold; /* Make headings bold */
  color: #343a40; /* Darker color for contrast */
}

#network p {
  margin: 0; /* Remove default margin */
  color: #6c757d; /* Muted text color */
  text-align: center; /* Center-align text */
}

/* Additional Responsive Design */
@media (max-width: 768px) {
  #network .col-md-3 {
      transform: none; /* Disable 3D rotation on smaller screens */
      box-shadow: none; /* Remove shadow on smaller screens */
  }
}
