/* authmodal.css */

/* Modal Container */
.auth-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  width: 90%;
  max-width: 450px;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  transition: all 0.3s ease;
}

/* Overlay Background */
.auth-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
  transition: all 0.3s ease;
}

/* Header */
.auth-modal h2 {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

/* Close Button */
.auth-modal button.close-btn {
  background: none;
  border: none;
  color: #888;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
  transition: color 0.3s ease;
}

.auth-modal button.close-btn:hover {
  color: #444;
}

/* Input Fields */
.auth-modal input[type="email"],
.auth-modal input[type="password"] {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.auth-modal input[type="email"]:focus,
.auth-modal input[type="password"]:focus {
  border-color: #007bff;
  outline: none;
}

/* Password Visibility Toggle */
.auth-modal .toggle-password {
  position: absolute;
  right: 1.25rem;
  top: calc(50% - 12px);
  cursor: pointer;
  color: #888;
  transition: color 0.3s ease;
}

.auth-modal .toggle-password:hover {
  color: #444;
}

/* Buttons */
.auth-modal button {
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.auth-modal button.primary-btn {
  background-color: #007bff;
  color: #ffffff;
}

.auth-modal button.primary-btn:hover {
  background-color: #0056b3;
}

.auth-modal button.secondary-btn {
  background-color: #ffffff;
  color: #007bff;
  border: 2px solid #007bff;
}

.auth-modal button.secondary-btn:hover {
  background-color: #007bff;
  color: #ffffff;
}

/* Spinner */
.spinner-border {
  width: 1rem;
  height: 1rem;
  border: 2px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: spin 0.75s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Error Message */
.auth-modal .text-danger {
  color: #ff4d4f;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.875rem;
}

/* Password Strength Indicator */
.auth-modal .text-green-500 {
  color: #28a745;
}

.auth-modal .text-yellow-500 {
  color: #ffc107;
}

.auth-modal .text-red-500 {
  color: #dc3545;
}

/* Responsive */
@media (min-width: 768px) {
  .auth-modal {
    width: 100%;
    max-width: 450px;
  }
}
