@tailwind base;
@tailwind components;
@tailwind utilities;


/* Custom Tailwind CSS for WhyChooseUs Component */

/* Custom class for section styling */
.why-choose-us {
  @apply py-12 bg-gray-50;
}

/* Custom class for heading */
.why-choose-us h2 {
  @apply text-3xl font-semibold mb-8 text-gray-800;
}

/* Custom class for icon styling */
.why-choose-us .fa {
  @apply text-blue-500;
}

/* Custom class for individual items */
.why-choose-us .row {
  @apply flex flex-wrap justify-center gap-8;
}

/* Custom class for each column */
.why-choose-us .col-md-4 {
  @apply bg-white p-6 rounded-lg shadow-md transition-transform transform hover:scale-105;
}

/* Custom class for item titles */
.why-choose-us h3 {
  @apply text-xl font-semibold mb-2 text-gray-900;
}

/* Custom class for item descriptions */
.why-choose-us p {
  @apply text-gray-600;
}


/* Custom Tailwind CSS for Network Component */

/* Section styling */
#network {
  @apply py-5 bg-light; /* This is fine if bg-light is defined in Tailwind config */
}

/* Heading styling */
#network h2 {
  @apply text-center text-primary text-3xl font-bold mb-4; /* These classes should be in Tailwind config */
}

/* Paragraph styling */
#network p {
  @apply text-center text-lg mb-5;
}


/* Image styling */
.img-fluid {
  max-width: 100%;
  height: auto;
}

/* Ensure rounded corners */
.rounded-lg {
  border-radius: 0.5rem; /* Adjust to match Tailwind's rounded-lg */
}
/* General Styles */


:root {
  --primary-color: #007bff;
  --secondary-color: #343a40;
  --transition-speed: 0.4s;
}

body {
  font-family: 'Poppins', sans-serif;
  line-height: 1.6;
  color: #333;
  margin: 0;
  padding-top: 45px; /* Adjust based on the height of the navbar */
}



@keyframes backgroundMove {
  from { transform: translateX(-100%); }
  to { transform: translateX(100%); }
}

@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}

@keyframes blink-caret {
  from, to { border-color: transparent; }
  50% { border-color: #ffcc00; }
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}


.btn-container {
  margin-top: 1.5rem; /* Adjust spacing as needed */
}

.btn-light {
  font-size: 1.5rem; /* Adjust button text size as needed */
  padding: 0.75rem 2rem; /* Adjust button padding as needed */
  background-color: #fff; /* Button background color */
  color: #333; /* Button text color */
  border: 2px solid #ffcc00; /* Button border color to match text */
  border-radius: 5px; /* Rounded corners */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition */
}

.btn-light:hover {
  background-color: #ffcc00; /* Button background on hover */
  color: #fff; /* Button text color on hover */
}

@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}

@keyframes blink-caret {
  from, to { border-color: transparent; }
  50% { border-color: #ffcc00; } /* Cursor color */
}

.hero h1 {
  font-size: 1.5rem; /* Reduced size for smaller screens */
}

.hero p {
  font-size: 0.75rem; /* Reduced size for smaller screens */
}

@media (min-width: 577px) and (max-width: 768px) {
  section {
      padding-top: 100px; /* Adjust for small tablets */
      padding-bottom: 70px;
  }

  .hero h1 {
      font-size: 2rem; /* Adjusted for medium screens */
  }

  .hero p {
      font-size: 0.875rem; /* Adjusted for medium screens */
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  section {
      padding-top: 80px; /* Adjust for larger tablets and small desktops */
      padding-bottom: 60px;
  }

  .hero h1 {
      font-size: 2.5rem; /* Adjusted for large screens */
  }

  .hero p {
      font-size: 1rem; /* Adjusted for large screens */
  }
}

@media (min-width: 993px) {
  .hero h1 {
      font-size: 3rem; /* Default for extra large screens */
  }

  .hero p {
      font-size: 1.125rem; /* Default for extra large screens */
  }
}

/* Header Animation */
.hero {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),  no-repeat center center;
}

/* Navbar Scroll Effect */
.navbar.scrolled {
  background-color: var(--secondary-color) !important;
  transition: background-color var(--transition-speed) ease;
}

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1030; /* Ensure the navbar is on top of other content */
  transition: background-color var(--transition-speed) ease;
}

