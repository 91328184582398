.hero {
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  transition: background-image 1s ease-in-out;
  overflow: hidden;
}

.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Dark overlay */
  z-index: 1;
}

.hero-content {
  z-index: 2; /* Place content above the overlay */
  padding: 1rem; /* Add padding for better spacing */
}

/* Styles for the typing effect text */
.typed-text {
  position: relative;
  display: inline-block;
  font-size: 1.5rem;
  color: #017c11d8; /* Default color */
  white-space: nowrap; /* Keeps the text on one line */
  overflow: hidden; /* Ensures no text overflow */
}

/* Blinking cursor animation */
.cursor {
  display: inline-block;
  width: 0.1em;
  height: 1em;
  background: #436b28;
  animation: blink 1s step-end infinite;
}

@keyframes blink {
  50% { opacity: 0; }
}

/* Styles for buttons */
.btn-container {
  margin-top: 1rem;
}

.btn-light {
  background-color: #fff;
  color: #333;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  cursor: pointer;
  text-decoration: none;
  border-radius: 0.3rem;
}


/* Keyframes */
@keyframes blink-caret {
  from, to { border-color: transparent; }
  50% { border-color: #ffcc00; } /* Cursor color */
}

/* Apply blink-caret to typed-text */
.typed-text::after {
  content: '|';
  animation: blink-caret 0.75s step-end infinite;
}

/* Responsive Styles */
.hero h1 {
  font-size: 1.5rem; /* Reduced size for smaller screens */
  margin-bottom: 1rem; /* Add spacing below header */
}

.hero p {
  font-size: 0.875rem; /* Slightly larger size for better readability */
  margin-bottom: 1.5rem; /* Add spacing below paragraph */
}

@media (min-width: 577px) and (max-width: 768px) {
  .hero h1 {
      font-size: 2rem; /* Adjusted for medium screens */
  }

  .hero p {
      font-size: 1rem; /* Adjusted for medium screens */
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .hero h1 {
      font-size: 2.5rem; /* Adjusted for large screens */
  }

  .hero p {
      font-size: 1.125rem; /* Adjusted for large screens */
  }
}

@media (min-width: 993px) {
  .hero h1 {
      font-size: 3rem; /* Default for extra large screens */
  }

  .hero p {
      font-size: 1.25rem; /* Default for extra large screens */
  }
}
