@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --transition-speed: 0.3s;
    --primary-color: #007bff; /* Example primary color */
    --secondary-color: #0056b3; /* Example secondary color */
}

/* Testimonials Section */
.testimonials {
    background: linear-gradient(to bottom, #f0f9ff, #ebf8ff);
    padding-top: 56px;
    padding-bottom: 5rem;
}

/* Container for both testimonials and feedback form */
.testimonials-container {
    display: flex;
    height: 100vh; /* Full viewport height */
    overflow: hidden; /* Prevent body scroll */
}

/* Section for testimonials */
.testimonials-section {
    flex: 2; /* Take more space than feedback form */
    padding: 20px;
    background-color: #f4f4f4; /* Background color for visual distinction */
    border-right: 1px solid #ddd; /* Border for separation */
    position: relative;
}

/* Fixed header for testimonials */
.testimonials-header {
    position: sticky;
    top: 0;
    background-color: #fff;
    padding: 10px;
    z-index: 20; /* Ensure it stays above other content */
    border-bottom: 1px solid #ddd;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Shadow for visual separation */
    margin-bottom: 20px; /* Space below the heading */
}

/* Container for testimonials list */
.testimonials-list {
    height: calc(100vh - 80px); /* Adjust based on header height */
    overflow-y: auto; /* Vertical scrolling */
}

/* Custom scrollbar */
.testimonials-list::-webkit-scrollbar {
    width: 8px;
}

.testimonials-list::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.testimonials-list::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* Individual testimonial item */
.testimonial-item {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 15px;
    display: flex;
    align-items: center;
    margin-bottom: 15px; /* Space between items */
}

/* Testimonial image */
.testimonial-img {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    margin-right: 15px;
}

/* Testimonial details */
.testimonial-details {
    flex: 1;
}


  /* Feedback Form */
  .feedback-form {
    background: #ffffff;
    padding: 25px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    margin-top: 30px;
    max-width: 600px; /* Set max-width for better layout on large screens */
    margin: auto; /* Center the form horizontally */
    transition: box-shadow 0.3s ease; /* Smooth shadow transition */
  }
  
  .feedback-form h3 {
    margin-bottom: 20px;
    font-size: 1.5rem; /* Larger font size for the heading */
    color: #202124;
    font-weight: 600; /* Bold heading */
    text-align: center; /* Center align heading */
  }
  
  .feedback-form .form-control {
    border-radius: 8px;
    border: 1px solid #dadce0;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 12px;
    font-size: 1rem; /* Slightly larger font size for readability */
    margin-bottom: 15px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */
  }
  
  .feedback-form .form-control:focus {
    border-color: #1a73e8; /* Highlight border color on focus */
    box-shadow: 0 0 5px rgba(26, 115, 232, 0.3); /* Shadow on focus */
    outline: none; /* Remove default outline */
  }
  
  .feedback-form .form-control::placeholder {
    color: #9e9e9e; /* Placeholder text color */
    opacity: 1; /* Ensure placeholder is fully opaque */
  }
  
  .feedback-form .btn-primary {
    background-color: #1a73e8;
    border: none;
    color: white;
    padding: 12px 24px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease; /* Smooth transitions */
    font-weight: 500; /* Slightly bolder text */
  }
  
  .feedback-form .btn-primary:hover {
    background-color: #1764d3;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
    transform: translateY(-2px); /* Slight lift effect on hover */
  }
  
  /* Responsive Design */
  @media (max-width: 767px) {
    .feedback-form {
        padding: 20px;
    }
  
    .feedback-form h3 {
        font-size: 1.2rem;
    }
  
    .feedback-form .form-control,
    .feedback-form .btn-primary {
        font-size: 0.9rem;
    }
  
    .feedback-form textarea {
        height: 120px;
    }
  }
  
  /* Mobile styles */
  @media (max-width: 767px) {
    body {
        font-size: 14px;
    }
  
    .hero-content {
        padding: 10px;
    }
  
    .testimonial-item {
        font-size: 14px;
    }
  
    .btn {
        padding: 10px 20px;
        font-size: 16px;
        border-radius: 5px;
    }
  }