/* Clients Section Styling */
#clients {
    background: linear-gradient(to bottom, #f0f9ff, #ebf8ff);
    padding-top: 56px;
    padding-bottom: 5rem;
}

/* Container for horizontal scrolling and centering */
.clients-grid-container {
    display: flex;
    flex-direction: row; /* Horizontal scrolling */
    overflow-x: auto; /* Enable horizontal scrolling */
    padding: 16px;
    gap: 16px; /* Space between grid items */
    scroll-snap-type: x mandatory; /* Ensure snapping for smooth scrolling */
}

/* Grid layout for clients */
.clients-grid {
    display: flex; /* Horizontal alignment */
    flex-wrap: nowrap; /* Prevent wrapping */
    gap: 16px; /* Space between grid items */
}

/* Styling for each client card */
.client-card {
    background-color: #696683;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s, box-shadow 0.3s;
    cursor: pointer;
    text-align: center;
    padding: 18px;
    max-width: 200px; /* Fixed width for all client cards */
    flex: 0 0 auto; /* Prevent shrinking and ensure cards stay at their width */
    scroll-snap-align: start; /* Snap each card to the start */
}

/* Hover effect for client card */
.client-card:hover {
    transform: scale(1.05) rotateX(5deg); /* Slight 3D effect */
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

/* Image styling for client cards */
.client-image {
    width: 100%;
    height: auto;
    border-bottom: 2px solid #f0f0f0;
    margin-bottom: 12px;
    object-fit: cover;
}

/* Title styling */
.client-card h4 {
    font-size: 1.25rem; /* Text size equivalent to text-xl */
    font-weight: 600; /* Font-semibold equivalent */
    margin-bottom: 0.5rem; /* mb-2 equivalent */
    text-align: center;
    transition: color 0.3s ease;
    transform: translateZ(20px); /* Pushing the title outwards for 3D effect */
}

/* Hover effect for title */
.client-card:hover h4 {
    color: #007bff; /* Assuming var(--primary-color) corresponds to #007bff */
}

/* Description styling */
.client-description {
    margin-top: 0.5rem; /* mt-2 equivalent */
    font-size: 0.875rem; /* Text size equivalent to text-sm */
    text-align: center;
    display: none; /* Hide description by default */
    transform: translateZ(10px); /* 3D effect */
}

/* Show description on hover */
.client-card:hover .client-description {
    display: block; /* Show the description on hover */
}

/* Loader and End Message styles */
.infinite-scroll-loader,
.infinite-scroll-end {
    text-align: center;
    margin: 16px 0;
    color: #333;
}

/* Responsive styles */
@media (max-width: 768px) {
    .client-card {
        max-width: 100px; /* Adjust card size for smaller screens */
    }
    .client-card h4 {
        font-size: 1rem; /* Adjust title size for smaller screens */
    }
    .client-description {
        font-size: 0.75rem; /* Adjust description size for smaller screens */
    }
}
