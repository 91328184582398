@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --transition-speed: 0.3s;
    --primary-color: #3490dc; /* Example primary color */
}

/* Section styling */
#resources {
    background: linear-gradient(to bottom, #ebf8ff, #f0f9ff);
    padding-top: 56px;
    padding-bottom: 5rem;
    @apply py-12 bg-gray-100;
}

/* Category container */
.category {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    transition: box-shadow var(--transition-speed) ease, transform var(--transition-speed) ease;
    cursor: pointer;
    perspective: 1000px; /* Adding perspective for 3D effect */
}

/* Hover effect for category container */
.category:hover {
    transform: scale(1.1) rotateX(15deg); /* Adding rotation for 3D effect */
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

/* Inner container */
.category-inner {
    position: relative;
    transform-style: preserve-3d; /* Preserving 3D effect for child elements */
}

/* Image styling */
.category img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    transition: transform var(--transition-speed) ease;
    transform: translateZ(30px); /* Pushing the image outwards for 3D effect */
}

/* Hover effect for image */
.category:hover img {
    transform: scale(1.1) translateZ(30px); /* Enhance 3D effect on hover */
}

/* Title styling */
.category-title {
    transition: color var(--transition-speed) ease;
    transform: translateZ(20px); /* Pushing the title outwards for 3D effect */
}

/* Hover effect for title */
.category:hover .category-title {
    color: var(--primary-color);
}

/* Description styling */
.category-description {
    margin-top: 10px;
    display: none; /* Hidden by default */
    transform: translateZ(10px); /* Pushing description outwards for 3D effect */
}

/* Show description on hover */
.category:hover .category-description {
    display: block; /* Show the description on hover */
}

/* Heading styling */
#resources h2 {
    @apply text-center text-4xl font-bold mb-6;
}

/* Paragraph styling */
#resources p {
    @apply text-center text-lg mb-10;
}

/* Responsive Resource card styling */
.resource-card {
    @apply bg-white p-6 rounded-lg shadow-lg text-center;
}

/* Image styling */
.resource-card img {
    @apply mb-4 rounded-lg w-full h-48 object-cover;
}

/* Title styling */
.resource-card h4 {
    @apply text-xl font-semibold mb-2;
}

/* List styling */
.resource-card ul {
    @apply list-disc list-inside text-left;
}
