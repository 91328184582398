/* General section styling */
#home {
  background: linear-gradient(to bottom, #ebf8ff, #f0f9ff);
  padding-top: 56px;
  padding-bottom: 5rem;
}

#home h2 {
  color: #007bff; /* Accent color for headings */
  font-size: 2.5rem; /* Ensure consistent heading size */
  text-align: center; /* Center the heading */
  margin-bottom: 1.5rem; /* Add space below heading */
}

#home p {
  line-height: 1.6; /* Improve readability with line height */
  color: #333; /* Set a standard text color */
  margin-bottom: 1.5rem; /* Add space below paragraphs */
  font-size: 1.125rem; /* Ensure consistent text size */
}

#home img {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for images */
  transition: transform 0.3s ease-in-out; /* Smooth zoom effect */
  border-radius: 8px; /* Slightly rounded corners for images */
}

#home img:hover {
  transform: scale(1.05); /* Zoom effect on hover */
}

#home .flex {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: flex-start;
}

#home .w-full {
  width: 100%;
}

#home .md\:w-1\/2 {
  width: 48%;
}

#home .mt-2 {
  margin-top: 1rem;
}

/* Responsive styling */
@media (max-width: 768px) {
  #home h2 {
      font-size: 2rem; /* Adjust heading size for smaller screens */
  }

  #home p {
      font-size: 1rem; /* Adjust text size for smaller screens */
  }

  #home .md\:w-1\/2 {
      width: 100%; /* Ensure full width on smaller screens */
  }
}
