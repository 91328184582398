/* Base Styling */
.contact {
  background: linear-gradient(to right, #f0f4f8, #ffffff);
  padding-top: 56px;
  padding-bottom: 5rem;
  font-family: 'Open Sans', sans-serif;
}

/* Headings */
.contact h2 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #2c3e50;
  margin-bottom: 2rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.contact h4 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #34495e;
  margin-bottom: 1.5rem;
}

/* Contact Information Styling */
.contact ul {
  list-style: none;
  padding: 0;
}

.contact ul li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.contact ul li i {
  font-size: 2rem;
  color: #7f8c8d;
  margin-right: 1.5rem;
  flex-shrink: 0;
}

.contact ul li h5 {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #2980b9;
}

.contact ul li p {
  margin: 0;
  color: #34495e;
  font-size: 0.9rem;
}

/* Form Styling */
.contact form {
  background: #ffffff;
  border: 1px solid #ecf0f1;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  max-width: 600px; /* Set a maximum width */
  margin: 0 auto; /* Center the form horizontally */
  height: auto; /* Allow height to adjust based on content */
}

.contact form label {
  font-weight: 700;
  color: #084c91;
  font-size: medium;
}

.contact form .form-control {
  border: 1px solid #dcdde1;
  border-radius: 2px;
  padding: 1rem; /* Adjust padding for consistency */
  font-size: 0.9rem;
  transition: border-color 0.3s ease;
}

.contact form .form-control:focus {
  border-color: #3498db;
  outline: none;
}

.contact form .form-select {
  border: 1px solid #dcdde1;
  border-radius: 4px;
  padding: 0.75rem;
  font-size: 0.9rem;
  background: #ffffff;
  transition: border-color 0.3s ease;
}

.contact form .form-select:focus {
  border-color: #3498db;
  outline: none;
}

.contact form textarea.form-control {
  resize: vertical;
}

/* Buttons */
.contact .btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.contact .btn:hover {
  transform: translateY(-2px);
}

/* WhatsApp Button */
.contact .btn.bg-green-500 {
  background-color: #25D366;
}

.contact .btn.bg-green-500:hover {
  background-color: #20c15a;
}

/* Submit Button */
.contact .btn.bg-blue-500 {
  background-color: #3498db;
}

.contact .btn.bg-blue-500:hover {
  background-color: #2980b9;
}

/* Link Hover Effect */
.contact .space-x-4 li a:hover {
  color: #3498db;
}

/* Responsive Design */
@media (min-width: 768px) {
  .contact h2 {
      font-size: 3rem;
  }

  .contact h4 {
      font-size: 1.75rem;
  }

  .contact .form-control {
      font-size: 1rem;
      padding: 1rem;
  }

  .contact .form-select {
      font-size: 1rem;
      padding: 1rem;
  }
}

@media (min-width: 1024px) {
  .contact h2 {
      font-size: 3.5rem;
  }

  .contact h4 {
      font-size: 2rem;
  }

  .contact form {
      padding: 2rem; /* Adjusted padding to maintain consistency on larger screens */
  }
}
